"use client";
import IconBox from "@/components/IconBox";
import { useState } from "react";

export function CopyButton({ text }) {
  const [copied, setCopied] = useState(false);

  return (
    <button
      className="hover:bg-gray-400/20 p-1 rounded absolute -top-2 -right-2 text-zinc-200"
      aria-label="Copy to clipboard"
      onClick={() => {
        navigator.clipboard.writeText(text);
        setCopied(true);
        setTimeout(() => setCopied(false), 1200);
      }}
    >
      {copied ? (
        <IconBox icon="check" color="green" size="lg" />
      ) : (
        <IconBox icon="copy" color="purple" size="lg" />
      )}
    </button>
  );
}
