import { Pre, highlight, InnerLine } from 'codehike/code';
import { CopyButton } from './CopyButton';
import { useState, useEffect } from 'react';
import { cn } from '@/utils';

function Line(props) {
  const width = props.totalLines.toString().length + 1;
  return (
    <div className="flex items-start gap-0">
      <span
        className="text-5 sticky left-0 bg-black pr-[1ch]"
        style={{ minWidth: `${width}ch` }}>
        {props.lineNumber}
      </span>
      <InnerLine merge={props} className="..." />
    </div>
  );
}

const lineNums = {
  name: 'line-numbers',
  Line,
};

function Code({ code, language, meta, lineNumbers, wrap, copy }) {
  const handlers = lineNumbers ? [lineNums] : [];
  const [highlightedCode, setHighlightedCode] = useState(null);

  useEffect(() => {
    highlight({ value: code, lang: language, meta }, 'monokai')
      .then(highlighted => {
        setHighlightedCode(highlighted);
      })
      .catch(error => {
        console.error(error);
      });
  }, [code, language, meta]);

  if (!highlightedCode) {
    return null;
  }

  return (
    <div className={cn('relative', copy ? 'pr-12' : '')}>
      {copy ? <CopyButton text={highlightedCode?.code} /> : null}
      <Pre
        code={highlightedCode}
        handlers={handlers}
        className={wrap ? 'wrap' : ''}
      />
    </div>
  );
}

export async function getCode({
  code,
  language,
  meta,
  lineNumbers,
  wrap,
  copy,
}) {
  return (
    <Code
      code={code}
      language={language}
      meta={meta}
      lineNumbers={lineNumbers}
      wrap={wrap}
      copy={copy}
    />
  );
}
